import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import {
  Box, Flex, Text, MenuButton,
} from 'theme-ui';

import Link from '../Link';

const Header = ({ siteTitle }) => (
  <Flex bg="primary" mb="1.45rem" sx={{ alignItems: 'center' }} pr={2}>
    <Box maxWidth={960} p="1.45rem" m="auto">
      <Text m={0} as="h1">
        <Link to="/" color="white">
          {siteTitle}
        </Link>
      </Text>
    </Box>
    <MenuButton />
  </Flex>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
