import React from 'react';
import PropTypes from 'prop-types';
import { Link as ThemedLink } from 'theme-ui';

const Link = ({ to, ...props }) => <ThemedLink href={to} {...props} />;

Link.propTypes = {
  to: PropTypes.string.isRequired,
  ...ThemedLink.propTypes,
};

export default Link;
